import { Box, useTheme } from "@mui/material";
import React from "react";

export default function CounterComponent({ data }) {
  const theme = useTheme();

  function formatNumber(num) {
    if (num >= 1_000_000_000)
    {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
    } else if (num >= 1_000_000)
    {
      return (num / 1_000_000).toFixed(1) + "M"; // Millions
    } else if (num >= 1_000)
    {
      return (num / 1_000).toFixed(1) + "K"; // Thousands
    } else
    {
      return num.toString(); // Less than 1K
    }
  }

  const formatted = data?.total_earnings?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const totalCount = [
    { title: "Total Users", count: data?.total_users ? formatNumber(data?.total_users) : 0 },

    {
      title: "Total Trainers",
      count: data?.total_trainers ? formatNumber(data?.total_trainers) : 0,
    },
    {
      title: "Total Earnings",
      count: formatted ?? 0,
    },
  ];

  return (
    totalCount &&
    totalCount.map((x, i) => {
      return (
        <Box
          key={i}
          sx={{
            width: "100%",
            border: `1px solid ${theme.palette.border.main}`,
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ width: "100%", fontSize: '18px', fontWeight: "400" }}>{x?.title}</Box>
          <Box sx={{ width: "100%", fontWeight: "600", fontSize: "24px" }}>
            {x.title.includes("Earnings") ? `$${x?.count}` : x?.count}
          </Box>
        </Box>
      );
    })
  );
}
