import {
  Box,
  TablePagination,
  Dialog,
  DialogContent,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import ErrorToaster from "../../Component/ErrorToaster";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import { DataGrid } from "@mui/x-data-grid";

const PAGE_SIZE_OPTIONS = [30];

export default function AppIssue() {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setloading] = useState(false);
  const theme = useTheme();

  const fetchAppIssueList = async (
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0]
  ) => {
    setloading(true);
    try
    {
      let queryParams = new URLSearchParams({
        count: page * pageSize,
      });
      const api = `${apiList.getAppIssueList}?${queryParams.toString()}`;
      const res = await AxiosInstance.get(api);
      const Rowdata = res?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      if (Rowdata?.length > 0)
      {
        setList(Rowdata);
        setTotalData(Rowdata?.total_count ?? Rowdata?.length);
      } else
      {
        setList([]);
        setTotalData(0);
      }
      setloading(false);
      setIsLoading(false);
    } catch (error)
    {
      setloading(false);
      setIsLoading(false);
      <ErrorToaster error={error} />;
    }
  };

  useEffect(() => {
    fetchAppIssueList(page);
  }, [page]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS;
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "username",
      headerName: "User Name",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      type: "number",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "description",
      headerName: "Description",
      type: "string",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "attachment",
      headerName: "Attachment",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const url = e?.row?.attachment.includes("instatrainme")
          ? e?.row?.attachment
          : `${e?.row?.attachment}`;
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={url}
              alt=""
              style={{
                height: "40px",
                width: "50px",
                borderRadius: "10px",
                border: "1px solid #E0E0E0",
                objectFit: "cover",
              }}
              onClick={() => {
                handleImageClick(url);
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "calc(100vh - 100px)",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "5px",
        mt: 1,
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            height: "calc(100vh - 100px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: "calc(100vh - 150px)",
            }}
          >
            <DataGrid
              rows={list}
              rowCount={-1}
              pagination
              paginationMode="server"
              disableColumnMenu={true}
              disableAutosize={true}
              disableColumnFilter={true}
              disableRowSelectionOnClick={true}
              getRowId={(e) => e.email}
              hideFooter
              loading={loading}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: "50px",
                backgroundColor: "black",
              }}
            >
              <img
                src={selectedImage}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "100vh",
                  borderRadius: "10px",
                }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
