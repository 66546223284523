import { Box, CircularProgress, Typography } from "@mui/material";
import NoDataImg from "../../../Common/NoData";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import { useEffect, useState } from "react";
import Loader from "../../../Common/Loader";
import RatingStar from "../../../Component/Rating";
import userImg from "../../../Assets/image/user/9720009.jpg";
import moment from "moment";

export default function RatingTabpanel({ id }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available

  const fetchData = async (count = 0) => {
    try
    {
      setLoading(true);
      const res = await AxiosInstance.get(
        `${apiList.TrainersDetails2}?t_id=${id}&type=2&count=${count}`
      );
      if (res?.data?.s === 1)
      {
        const newData = res?.data?.r?.data;
        setData((prevData) => count === 0 ? newData : [...prevData, ...newData]);
        setHasMore(newData.length > 0);
      } else
      {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setData([]);  
    setCount(0); 
    setHasMore(true);
    fetchData(0);
  }, [id]);

  useEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } =
        event.target.scrollingElement;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        !loading &&
        hasMore
      )
      {
        setCount((prevCount) => prevCount + 30);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        height: { md: "calc(100vh - 330px)" },
        overflow: { md: "scroll" },
        // minHeight: '350px',
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          height: "50px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          position: "sticky",
          top: "0",
        }}
      >
        Rating & Review
      </Typography>
      <Box
        sx={{
          p: 2,
          minHeight: "200px",
          height: "calc(100% - 50px)",
          overflow: "scroll",
        }}
        className='HideScrollBar'

      >
        {loading && count === 0 ? (
          <Loader />
        ) : data && data?.length > 0 ? (
          <Box>
            {data?.map((x, i) => (
              <Box
                key={i}
                sx={{
                  p: 2,
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 1px 1px 10px -4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  mb: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.7rem",
                    width: "100%",
                  }}
                >
                  <Box>
                    <img
                      src={x?.profile_img === "" ? userImg : x?.profile_img}
                      style={{
                        borderRadius: "12px",
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.54)",
                      }}
                      alt=""
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        fontWeight: "600",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1rem",
                      }}
                    >
                      <Box>{`${x?.first_name} ${x?.last_name}`}</Box>
                      <Box sx={{ opacity: "0.7" }}>
                        {moment(x?.created_at).fromNow()}{" "}
                      </Box>
                    </Box>
                    <Box>
                      <RatingStar rating={x?.rating_point} />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      color: `rgba(0, 0, 0, 0.8)`,
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    {x?.review}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
        {loading && count > 0 && <CircularProgress />}
      </Box>
    </Box>
  );
}
