import { BrowserRouter } from "react-router-dom";
import "./App.css";
import StateStoreProvider from "./GlobalContext/StateStore";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Common/Theme/theme";
import RoutesList from "./Common/Routes/Routes";

function App() {
  return (
    <StateStoreProvider>
      <ThemeProvider theme={theme("light")}>
        <BrowserRouter>
          {/* All screens routes file is here...*/}
          <RoutesList />
        </BrowserRouter>
      </ThemeProvider>
    </StateStoreProvider>
  );
}

export default App;


