import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Divider, useTheme } from "@mui/material";

export default function CustomDateRangePicker(props) {
  const { endDate, setEndDate, startDate, setStartDate } = props;

  const theme = useTheme();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          padding: "3px",
          width: "100%",
          borderRadius: "8px",
          fontSize: "14px",
          display: "flex",
          border: "0.1rem solid #eee",
          background: theme.palette.background.thead,
        }}
      >
        <DatePicker
          //   label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(startProps) => <input {...startProps.inputProps} />}
          format="DD/MM/YY"
          sx={{
            // border:"none"
            "& fieldset": {
              border: "none",
            },
          }}
        />
        <Divider />
        <DatePicker
          //   label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          format="DD/MM/YY"
          renderInput={(endProps) => <input {...endProps.inputProps} />}
          sx={{
            // border:"none"
            "& fieldset": {
              border: "none",
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}
