import {
  Box,
  Button,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import viewicon from "../../Assets/image/user/223_frame.png";
import NoDataImg from "../../Common/NoData";
import { PathList } from "../../Common/Routes/Path";
import { useNavigate } from "react-router-dom";
import star from "../../Assets/image/Trainers/Vector.png";
import Loader from "../../Common/Loader";
import { DataGrid } from "@mui/x-data-grid";

export default function UserSessionDetailsTable(props) {
  const theme = useTheme();

  const {
    sessiondetails,
    page,
    isLoading,
    totalData,
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    PAGE_SIZE_OPTIONS,
    loading,
  } = props;
  const navigate = useNavigate();
  const data = sessiondetails.map((x, i) => {
    return { ...x, rowId: i + 1 };
  });


  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowId",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "trainer_name",
      headerName: "Trainer Name",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "rating_count",
      headerName: "Rate",
      type: "string",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              gap: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {e?.row?.rating_point > 0 ? (
              <>
                <Typography>{e?.row?.rating_point}</Typography>
                <img src={star} alt="" style={{ marginBottom: "3px" }} />
              </>
            ) : (
              "-"
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "booking_date",
      headerName: "Booking Date",
      minWidth: 260,
      flex: 1,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span>{`${moment(cell?.row?.booking_date).format("DD/MM/YYYY")}`}</span>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "type",
      headerName: "Type",
      width: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        if (cell?.row?.sub_type === 1)
        {
          return <span style={{ color: "#C37500" }}>Virtual</span>;
        } else
        {
          return <span style={{ color: "#1451B6" }}>Onsite</span>;
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "total_amount",
      headerName: "Price",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      renderCell: (cell) => `$${cell?.row?.total_amount}`,
    },

    {
      headerClassName: "super-app-theme--header",
      field: "session_status",
      headerName: "Status",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            style={{
              background:
                cellValues?.row?.session_status === 1
                  ? theme.palette.button.pending
                  : cellValues?.row?.session_status === 2
                    ? theme.palette.button.confirm
                    : cellValues?.row?.session_status === 3
                      ? theme.palette.button.otw
                      : cellValues?.row?.session_status === 4
                        ? theme.palette.button.arrived
                        : cellValues?.row?.session_status === 5
                          ? theme.palette.button.session
                          : cellValues?.row?.session_status === 6
                            ? theme.palette.button.complete
                            : theme.palette.button.cancel,
              fontWeight: "500",
              textTransform: "none",
              width: "120px",
              color: "Black",
            }}
          >
            {status[cellValues?.row?.session_status - 1]?.status}
          </Button>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      flex: 1,
      minWidth: 60,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewicon}
            alt=""
            onClick={() =>
              navigate(`${PathList.Booking_Details}/${e?.row?.id}`)
            }
          />
        </Box>
      ),
    },
  ];

  const status = [
    { id: 1, status: "Pending" },
    { id: 2, status: "Confirmed" },
    { id: 3, status: "On the way" },
    { id: 4, status: "Arrived" },
    { id: 5, status: "Start session" },
    { id: 6, status: "Completed" },
    { id: 7, status: "Cancelled" },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <Box
      sx={{
        height: "calc(100vh - 450px)",
      }}
    >
      <DataGrid
        pagination
        loading={loading}
        rowCount={totalData}
        rows={data ?? []}
        disableColumnMenu={true}
        disableAutosize={true}
        paginationMode="server"
        hideFooter
        disableColumnFilter={true}
        getRowId={(e) => e?.id}
        slots={{ noRowsOverlay: NoDataImg }}
        columns={columns}
        sx={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          ".MuiDataGrid-cell": { textAlign: "center" },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
            color: "white",
          },
          "& .super-app-theme--header": {
            background: theme.palette.primary.Blend,
          },
        }}
        pageSize={rowsPerPage}
      />
      <TablePagination
        component="div"
        count={totalData}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
