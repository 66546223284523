import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MainBG from "../../Assets/image/login/Main.png";
import LoginFormComponent from "./LoginFormComponent";
import ForgotPassordComponent from "./ForgotPasswordScreen";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";

export default function LoginScreen() {
  const [isLoginScreen, setisLoginScreen] = useState(true);
  const nav = useNavigate();
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setloading(true);
    const isLogin = JSON.parse(localStorage.getItem("userInfo"));
    setloading(false);
    if (isLogin) {
      nav(PathList.Dashboard);
    }
  }, [nav]);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `url(${MainBG}) center center / cover`,
      }}
    >
      {!loading && (
        <Box>
          {isLoginScreen ? (
            <LoginFormComponent setisLoginScreen={setisLoginScreen} />
          ) : (
            <ForgotPassordComponent setisLoginScreen={setisLoginScreen} />
          )}
        </Box>
      )}
    </Box>
  );
}
