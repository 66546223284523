import { createTheme } from "@mui/material";

export const theme = (mode) =>
  createTheme({
    palette: {
      primary: {
        //button gradient primary + secondary
        main: "#7AACFF",
        paper: "#3C75D4",
        Blend: `linear-gradient(#7AACFF ,#3C75D4)`,
        BlendO: `linear-gradient(#7AACFF80 ,#3C75D480)`,
      },

      background: {
        //sidebar
        main: "#0E1726",
        paper: "#FFFFFF",
        trow: "#EFEFEF",
        thead: "#F6F6F6",
        select: "#5287E2",
      },

      border: {
        main: "#DDDDDD",
      },

      button: {
        //delete btn
        delete: {
          main: "#E76C64",
          light: "#E50000",
        },
        pending: "#BFFBE0",
        confirm: "#CEE5FF",
        otw: "#FFE6C1",
        arrived: "#87CEEB",
        session: "#FFCDE5",
        complete: "#7AACFF",
        cancel: "#ff8989",
      },

      text: {
        //typography
        main: "#ffffff",
        button: "#ffffff",
        primary: "#000000",
        secondary: "#FFFFFF",
        selectTitle: "#252525",
        selectIcon: "#969696",
      },
    },
  });
