import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import StripeChargeUpdateModal from "./StripeChargeUpdateModal";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { useState } from "react";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import ErrorMsgComponent from "../../Component/Error_msg_Component";

function SettingScreen() {
  const [data, setdata] = useState(0);
  const theme = useTheme();
  const [loading, setloading] = useState(true);
  const [isloading, setLoading] = useState(false);
  // const [charge, setdata] = useState(data);
  const [error, seterror] = useState("");
  const [isSubmitted, setisSubmitted] = useState(false);
  const Ref = useRef(data);
  const fetchData = async () => {
    try
    {
      const res = await AxiosInstance.get(apiList.getStripeCharge);
      if (res.data.s === 1 && res.data.r.status === 1)
      {
        setdata(res.data.r.charge);
        Ref.current = res.data.r.charge;
      } else
      {
        toast.error(res.data.m);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setisSubmitted(true);
    const { value } = e.target;
    if (value < 0)
    {
      setdata(0);
      seterror("");
    } else if (value < 100)
    {
      setdata(value);
      seterror("");
    } else if (value >= 100)
    {
      seterror("max limit exceeded.");
    }
  };

  const handleSubmit = async () => {
    try
    {
      setLoading(true);
      setisSubmitted(true);
      const res = await AxiosInstance.post(apiList.updateCharge, {
        charge: data,
      });
      if (res.data.s === 1)
      {
        await fetchData();
        // handleClose();
        toast.success("Updated successfully.");
        setLoading(false);
      } else
      {
        toast.error(res.data.m);
        setLoading(false);
      }
    } catch (error)
    {
      console.error("Error Stripe Charge update:", error);
      setLoading(false);
    }
  };

  // console.log(Ref.current === data);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ height: "calc(100vh - 90px)", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              width: { sm: "100%", md: "70%", lg: "50%", xl: '35%' },
              boxShadow: "3px 3px 7px 0 #00000040",
              overflow: "hidden",
              borderRadius: "9px",
              height: "fit-content",
              mt: 1.5,
            }}
          >
            <Box
              sx={{
                background: theme.palette.primary.Blend,
                width: "100%",
                color: "white",
                padding: "10px",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>Stripe Charge</Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "30%",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Current (%)
              </Box>
              <Box
                sx={{
                  width: "70%",
                  padding: "10px",
                  borderBottom: `1px solid ${theme.palette.background.thead}`,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {/* <Box>{data}%</Box>{" "} */}
                <TextField
                  type="number"
                  name="charge"
                  id="charge"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  error={isSubmitted && error}
                  placeholder="charge"
                  onChange={handleChange}
                  value={data}
                  onKeyUp={(key) =>
                    key.keyCode === 13 &&
                    (Ref.current !== data || isloading) &&
                    handleSubmit()
                  }
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      padding: "4px 0",
                    },
                  }}
                />
                {isSubmitted && error && <ErrorMsgComponent error={error} />}
              </Box>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "200px",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    width: "100%",
                    fontFamily: "Helvetica",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                    padding: "8px",
                    border: "none",
                    color: theme.palette.text.button,
                    background: theme.palette.primary.Blend,
                    cursor: Ref.current !== data && "pointer",
                  }}
                  disabled={isloading || Ref.current === data}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {isloading ? <CircularProgress size={26} /> : "Update"}
                </button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default SettingScreen;
