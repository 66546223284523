import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TrainerDetailsCategoriesTable from "./TabScreens/TrainerDetailsCategoriesTable";
import { useTheme } from "@mui/material/styles";
import Loader from "../../Common/Loader";
import NoDataImg from "../../Common/NoData";
import ErrorToaster from "../../Component/ErrorToaster";
import ConfirmationModal from "../../Component/ConfirmationModal";
import Experince from "./TabScreens/Experince";
import SlotDetails from "./TabScreens/SlotDetails";
import Licence from "./TabScreens/Licence";
import Certification from "./TabScreens/Certification";
import Earning from "./TabScreens/Earning";
import OrderDetails from "./TabScreens/OrderDetails";
import RatingTabpanel from "./TabScreens/Rating";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { AppDomain } from "../../Common/Constant";
import pdficon from "../../Assets/image/Trainers/pdficon.png";

import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import BioModal from "./BioModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: { xs: 0, lg: 3 }, py: { xs: 0, lg: 0 } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TrainerDetails = ({ a11yProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [secdata, setSecData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setactionLoading] = useState(false);

  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const fetchUserData = async (count = 0) => {
    setIsLoading(true);
    try
    {
      const response = await AxiosInstance.get(
        `${apiList.TrainersDetails}?id=${id}&count=${count}`
      );

      if (response?.data?.s === 1 && response.data?.r)
      {
        setData(response.data?.r);
      }

      setIsLoading(false);
    } catch (error)
    {
      toast.error(`Error: ${error.message}`);
      setIsLoading(false);
    }
  };
  const fetchUserData1 = async (count = 0) => {
    try
    {
      const res = await AxiosInstance.get(
        `${apiList.TrainersDetails1}?id=${id}&count=${count}`
      );
      res?.data?.s === 1 && res?.data?.r && setSecData(res?.data?.r);
      setIsLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUserData();
    fetchUserData1();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDelete = async (id, status) => {
    try
    {
      setactionLoading(true);
      const res = await AxiosInstance.post(`${apiList.DeleteUser}`, {
        id: id,
        status: status,
      });
      if (res?.data?.s === 1)
      {
        toast.success("Trainer deleted successfully");
        navigate(-1);
      } else
      {
        toast.error(res?.data?.m);
      }
      setactionLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setactionLoading(false);
    }
  };

  const handleBlock = async (id, status) => {
    try
    {
      setactionLoading(true);
      const res = await AxiosInstance.post(`${apiList.DeleteUser}`, {
        id: id,
        status: status,
      });
      if (res?.data?.s === 1)
      {
        toast.success(
          `Trainer ${status === -1 ? "Restricted" : "Unrestricted"
          } successfully.`
        );
        fetchUserData();
      } else
      {
        toast.error(res?.data?.m);
      }
      setactionLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setactionLoading(false);
    }
  };

  const optionList = [
    {
      id: 0,
      title: "Basic Details",
      data: <Experince data={data} />,
    },
    {
      id: 1,
      title: "Categories",
      data: (
        <Box sx={{ width: "100%", height: "50px" }}>
          <TrainerDetailsCategoriesTable
            categoriesTable
            categories={data?.options?.categories}
          />
        </Box>
      ),
    },
    {
      id: 2,
      title: "Slot Details",
      data: <SlotDetails />,
    },
    {
      id: 3,
      title: "Licence/ID",
      data: <Licence data={secdata} pdficon={pdficon} />,
    },
    {
      id: 4,
      title: "Certifications",
      data: <Certification data={secdata} pdficon={pdficon} />,
    },
    {
      id: 5,
      title: "Earnings",
      data: <Earning id={id} />,
    },
    {
      id: 6,
      title: "Rating & Review",
      data: <RatingTabpanel id={id} />,
    },
    {
      id: 7,
      title: "Session Details",
      data: <OrderDetails id={id} />,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "calc(100vh - 77px)",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : data ? (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  m: 3,
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: { xs: "center", md: "start" },
                }}
              >
                <Box>
                  {data?.profile_img ? (
                    <a
                      href={
                        data?.profile_img.includes("instatrainme")
                          ? data?.profile_img
                          : `${AppDomain}/${data?.profile_img}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          data?.profile_img.includes("instatrainme")
                            ? data?.profile_img
                            : `${AppDomain}/${data?.profile_img}`
                        }
                        alt=""
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "1px solid #E0E0E0",
                          objectFit: "cover",
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      src={dummyimage}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        border: "1px solid #E0E0E0",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    pl: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {data?.trainer_name}&nbsp;
                    <span style={{ color: "grey", fontSize: "18px" }}>
                      {`${data?.gender && data?.gender === 1
                        ? "(Male)"
                        : data?.gender === 2
                          ? "(Female)"
                          : data?.gender === 3
                            ? "(Other)"
                            : ""
                        }`}
                    </span>
                    {/* <RatingStar rating={data?.rating_count} /> */}
                    &nbsp;
                    <span
                      style={{
                        color: "black",
                        fontSize: "20px",
                        // outline:"1px solid red",
                        paddingTop: "0.15rem",
                        display: data?.rating_point > 0 ? "flex" : "none",
                      }}
                    >{`${data?.rating_point} `}</span>
                    {data?.rating_point > 0 ? (
                      <StarRateRoundedIcon style={{ color: "#FFA534" }} />
                    ) : (
                      <StarOutlineRoundedIcon style={{ color: "grey" }} />
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <a
                      style={{
                        color: "#000",
                        textDecoration: "none",
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      href={`mailto:${data?.email}`}
                    >
                      {" "}
                      {data?.email}
                    </a>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Trained People : {data?.trained_people}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#5B5B5B",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // width: '200px'
                    }}
                  >
                    {/* {data?.bio?.length > 200 ? (
                      <>
                        {data?.bio?.slice(0, 180) +
                          <BioModal data={data?.bio} />}
                      </>
                    ) : (
                      data?.bio
                    )} */}
                    {data?.bio}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: data?.is_admin_approval === 1 ? "flex" : "none",
                  // display: data?.is_admin_approval === 1 ? "flex" : "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: { xs: "10px", md: "10px" },
                  pb: { xs: 3, md: 0 },
                }}
              >
                <ConfirmationModal
                  handleClick={handleBlock}
                  ModalTitle={data?.status === -1 ? "Unrestrict" : "Restrict"}
                  submitBtnTitle={"Confirm"}
                  bgColor={"#F2F2F2"}
                  color={data?.status === 1 ? "#000" : "red"}
                  id={id}
                  loading={actionLoading}
                  status={data?.status === 1 ? -1 : 1}
                  disable={
                    data?.status === -2 ||
                      data?.status === 0 ||
                      data?.is_admin_approval !== 1
                      ? true
                      : false
                  }
                />
                <ConfirmationModal
                  handleClick={handleDelete}
                  ModalTitle={
                    data?.status === 0 || data?.status === -2
                      ? "Deleted"
                      : "Delete"
                  }
                  submitBtnTitle={"Confirm"}
                  bgColor={"#FACCCC"}
                  color={"red"}
                  id={id}
                  loading={actionLoading}
                  status={-2}
                  disable={
                    data?.status === 0 ||
                      data?.status === -2 ||
                      data?.is_admin_approval !== 1
                      ? true
                      : false
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                // flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                gap: "1rem",
                flexDirection: isSmallScreen ? "column" : "row",
                height: isSmallScreen ? "auto" : "460px",
                pb: 3,
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  width: isSmallScreen ? "100%" : "300px",
                  // border: "solid red",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    background:
                      "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    p: "1rem",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  Options
                </Box>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderColor: "transparent",
                    width: "100%",
                  }}
                >
                  {optionList.map((opt, i) => (
                    <Tab
                      key={i}
                      label={opt.title}
                      sx={{
                        borderBottom: isSmallScreen
                          ? "none"
                          : "1px solid #EFEFEF",
                        width: "100%",
                        color: "black",
                        alignItems: "flex-Start",
                        textTransform: "none",
                      }}
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: { xs: "100%", lg: "calc(100% - 300px)" },
                  // height: "100%",

                  position: "relative",
                }}
              >
                {optionList.map((x, i) => (
                  // <Box sx={{width:'1000px'}}>
                  <TabPanel value={value} index={i}>
                    {x.data}
                  </TabPanel>
                  // </Box>
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <NoDataImg />
        )}
      </Box>
    </>
  );
};

export default TrainerDetails;

// const optionList = [
//   "Basic Details",
//   "Categories",
//   "Slot Details",
//   "License/ID",
//   "Certifications",
//   "Earnings",
//   "Rating & Review",
//   "Order Details",
// ];
