import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default function Loader() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#7AACFF" />
            <stop offset="100%" stopColor="#3C75D4" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
      <Box
        sx={{
          background: "linear-gradient(90deg, #7AACFF, #3C75D4)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "1.2rem",
          marginTop: "10px",
        }}
      >
        Loading . . .
      </Box>
    </Box>
  );
}
