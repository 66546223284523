import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Logo from "../Assets/image/dash/logo.svg";

import { Button, CircularProgress, useTheme } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  bgcolor: "background.paper",
  borderRadius: "12px",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationModal(props) {
  const {
    handleClick,
    ModalTitle,
    submitBtnTitle,
    loading,
    bgColor,
    color,
    status,
    id,
    disable,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const handleSubmit = async () => {
    await handleClick(id, status);
    handleClose();
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        disabled={disable}
        sx={{
          bgcolor: bgColor,
          textTransform: "none",
          color: color,
          fontSize: "14px",
          width: "150px",
          mx: { md: 5 },
          "&:hover": { bgcolor: bgColor },
        }}
      >
        {ModalTitle}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <img src={Logo} alt="Logo" srcSet="" />
          </Box>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, textAlign: "center" }}
          >
            Are you sure ?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              mt: 1,
              fontSize: "18px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleClose}
            >
              Cancle
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                background: theme.palette.primary.Blend,
                color: "#eee",
              }}
              onClick={!loading && handleSubmit}
            >
              {loading ? <CircularProgress size={26} /> : submitBtnTitle}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
