import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import NoDataImg from "../../../Common/NoData";

export default function TrainerDetailsCategoriesTable({ categories }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "calc(100vh - 350px)",
        overflowY: "scroll",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        // minHeight: '350px',
      }}
      className="HideScrollBar"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          sx={{
            ".MuiTableCell-root": {
              padding: "10px",
            },
            position: "sticky",
            // zIndex: 1,
            top: "0",
            // border: "solid red",
            height: "50px",
            boxSizing: "border-box",
            width: "100%",
            background:
              "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          }}
        >
          <TableRow>
            <TableCell
              sx={{ color: "white", textAlign: "center", width: "50px" }}
            >
              Sr.
            </TableCell>
            <TableCell
              align="left"
              sx={{ color: "white", textAlign: "center", width: "150px" }}
            >
              Category Name{" "}
            </TableCell>
            <TableCell align="left" sx={{ color: "white" }}>
              Sub Category
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            maxHeight: { md: "calc(100vh - 350px)" },
            overflow: { md: "scroll" },
          }}
        >
          {categories?.length > 0 ? (
            categories?.map((category, i) => {
              return (
                category?.status === 1 && (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "60px",
                      }}
                    >
                      {category?.id}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "150px",
                      }}
                    >
                      {category?.cat_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        // width: "calc(100% - 220px)",
                        // overflow: "scroll",
                        // border: "solid red",
                        // flex: 1,
                      }}
                    >
                      <TableRow
                        sx={{
                          // width: "100%",
                          display: "flex",
                          // overflow: "scroll",
                          justifyContent: "start",
                          flexWrap: "wrap",
                          border: "none",
                        }}
                      >
                        {category?.sub_cat.map((sub, x) => {
                          return (
                            <TableCell
                              key={`cat_${x}`}
                              style={{ border: "none" }}
                            >
                              <Box
                                sx={{
                                  background: "#CEE5FF",
                                  // border: "solid red",
                                  width: "180px",
                                  px: 1,
                                  py: 1,
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#3f77D5",
                                  }}
                                >
                                  {sub.sub_cat_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Instant : ${sub.instant_price}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Pre Booking : ${sub.pre_price}
                                </Typography>
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableCell>

                    {/* <TableCell>{category?.id}</TableCell> */}
                  </TableRow>
                )
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
                  <NoDataImg />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
