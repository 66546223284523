import { Box, Typography } from "@mui/material";
import React from "react";

export default function ErrorMsgComponent({ error }) {
  return (
    <Box sx={{ display: error ? "flex" : "none" }}>
      <Typography color={"error"} sx={{ fontSize: "0.9rem", ml: "0.25rem" }}>
        {error}
      </Typography>
    </Box>
  );
}
