import { Route, Routes } from "react-router-dom";
import { PathList } from "./Path";
import LoginScreen from "../../Screens/Login/LoginScreen";
import UserManagementScreen from "../../Screens/UserManagementScreen/UserManagementScreen";
import TrainerManagementScreen from "../../Screens/TrainerManagementScreen/TrainerManagementScreen";
import BookingDetaileScreen from "../../Screens/BookingDetailsScreen/BookingDetaileScreen";
import BookingScreen from "../../Screens/BookingScreen/BookingScreen";
import UserDetailsPage from "../../Screens/UserManagementScreen/UserDetailsPage";
import TrainerDetails from "../../Screens/TrainerManagementScreen/TrainerDetails";
import MainLayout from "../../Screens/Layout/Layout";
import { Middleware } from "./Middleware";
import DashboardScreen from "../../Screens/Dashboard/DashboardScreen";
import NotificationScreen from "../../Screens/NotificationScreen/NotificationScreen";
import FAQs from "../../Screens/FAQs/FAQs";
import AppIssue from "../../Screens/AppIssue/AppIssue";
import SettingScreen from "../../Screens/Setting/SettingScreen";

export default function RoutesList() {
  return (
    <Routes>
      <Route path={PathList.Login} element={<LoginScreen />} />
      <Route
        path={PathList.Dashboard}
        element={
          <Middleware children={<MainLayout element={<DashboardScreen />} />} />
        }
      />
      <Route
        path={PathList.Booking}
        element={
          <Middleware children={<MainLayout element={<BookingScreen />} />} />
        }
      />
      <Route
        path={PathList.UserManagement}
        element={
          <Middleware
            children={<MainLayout element={<UserManagementScreen />} />}
          />
        }
      />
      <Route
        path={PathList.Trainer}
        element={
          <Middleware
            children={<MainLayout element={<TrainerManagementScreen />} />}
          />
        }
      />
      <Route
        path={PathList.Notification}
        element={
          <Middleware
            children={<MainLayout element={<NotificationScreen />} />}
          />
        }
      />

      <Route
        path={`${PathList.Booking_Details}/:id`}
        element={
          <Middleware
            children={<MainLayout element={<BookingDetaileScreen />} />}
          />
        }
      />
      <Route
        path={`${PathList.UserDetails}/:id`}
        element={
          <Middleware children={<MainLayout element={<UserDetailsPage />} />} />
        }
      />
      <Route
        path={`${PathList.TrainerDetails}/:id`}
        element={
          <Middleware children={<MainLayout element={<TrainerDetails />} />} />
        }
      />
      <Route
        path={PathList.FAQs}
        element={<Middleware children={<MainLayout element={<FAQs />} />} />}
      />
      <Route
        path={PathList.AppIssue}
        element={
          <Middleware children={<MainLayout element={<AppIssue />} />} />
        }
      />
      <Route
        path={PathList.Setting}
        element={
          <Middleware children={<MainLayout element={<SettingScreen />} />} />
        }
      />
    </Routes>
  );
}
