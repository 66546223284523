import { createContext, useState } from "react";

export const StateStore = createContext();

export default function StateStoreProvider({ children }) {
  const [first, setfirst] = useState();
  return (
    <StateStore.Provider value={{ first, setfirst }}>
      {children}
    </StateStore.Provider>
  );
}
