import {
  Box,
  Button,
  // Button,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ForgotPassword } from "../../Common/YupSchema";
import ErrorMsgComponent from "../../Component/Error_msg_Component";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";

export default function ForgotPassordComponent({ setisLoginScreen }) {
  const theme = useTheme();
  const [isSubmitted, setisSubmitted] = useState(false);
  const [loading, setloading] = useState(false);
  const { values, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: async (value, { resetForm }) => {
        try
        {
          setloading(true);
          const response = await AxiosInstance.post(
            apiList.ForgotPassword,
            value
          );
          if (response?.data?.s === 1)
          {
            toast.success(
              `A password reset link has been successfully sent to ${value?.email}.`
            );
            setisLoginScreen(true);
            resetForm();
          } else
          {
            toast.error(response?.data?.m);
          }
          setloading(false);
        } catch (error)
        {
          console.log(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
          );
          setloading(false);
        }
      },
      validationSchema: ForgotPassword,
    });

  const handleFormData = (e) => {
    e.preventDefault();
    setisSubmitted(true);
    handleSubmit();
  };
  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "2rem",
        borderRadius: "12px",
        fontFamily: "Helvetica",
        width: { xs: "99%", md: "400px", xl: "500px" },
      }}
    >
      <h2
        style={{ marginTop: "0.5rem", fontWeight: "700", fontSize: "1.5rem" }}
      >
        Forgot Password ?
      </h2>
      <Box
        style={{
          marginTop: "0.5rem",
          fontSize: "1.05rem",
        }}
      >
        Enter your e-mail to get reset password link.
      </Box>
      <form onSubmit={handleFormData}>
        <Box sx={{ mt: "0.5rem" }}>
          <Box>E-mail</Box>
          <Box sx={{ mt: "0.5rem" }}>
            <TextField
              type="email"
              name="email"
              id="email"
              placeholder="example@gmail.com"
              value={values.email}
              onChange={handleChange}
              error={isSubmitted && errors.password ? true : false}
              sx={{
                width: "100%",
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                  padding: "10px 0",
                },
              }}
            />
            <ErrorMsgComponent error={errors?.email} />
          </Box>
        </Box>
        <Box sx={{ padding: '0.5rem 0 0.5rem 0', display: 'flex' }}  ><Typography sx={{ cursor: 'pointer', '&:hover': { color: 'grey' } }} onClick={() => setisLoginScreen(true)}>Back to login</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              width: "100%",
              fontFamily: "Helvetica",
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: "700",
              fontSize: "1rem",
              color: theme.palette.text.button,
              background: theme.palette.primary.Blend,
            }}
          // onClick={() => setisLoginScreen(true)}
          >
            {loading ? <CircularProgress size={26} /> : "Submit"}
          </Button>
        </Box>

      </form>
    </Box>
  );
}
