import { Box, MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

const SlotDrop = ({
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
}) => {
    const [yearRange, setYearRange] = useState([]);
    const Months = [
        { id: 1, title: "Jan" },
        { id: 2, title: "Feb" },
        { id: 3, title: "Mar" },
        { id: 4, title: "Apr" },
        { id: 5, title: "May" },
        { id: 6, title: "Jun" },
        { id: 7, title: "Jul" },
        { id: 8, title: "Aug" },
        { id: 9, title: "Sep" },
        { id: 10, title: "Oct" },
        { id: 11, title: "Nov" },
        { id: 12, title: "Dec" },
    ];


    const YearRangeFetch = (start, end) => {
        let aray = [];
        for (let i = start; i <= end + 3; i++)
        {
            aray.push(i);
        }
        return setYearRange(aray);
    };
    useEffect(() => {
        YearRangeFetch(2024, moment().year());
    }, []);

    const customMenuProps = {
        PaperProps: {
            sx: {
                maxHeight: 170,
            },
        },
    };

    useEffect(() => { }, [selectedYear, selectedMonth]);
    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.15rem",
                padding: "10px",
                color: "white",
                alignItems: "center",
            }}
        >
            <Select 
                value={selectedMonth}
                MenuProps={customMenuProps}
                sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",

                    ".MuiSelect-select": {
                        padding: "4px 12px",
                    },

                    ".MuiList-root": {
                        height: "300px",
                        overflowX: "scroll",
                    },
                }}
                onChange={(e) => {
                    const {
                        target: { value },
                    } = e;

                    setSelectedMonth(value);
                }}
            >
                <MenuItem
                    value={0}
                    sx={{ padding: "7px", display: "none", color: "grey" }}
                >
                    Month
                </MenuItem>
                {Months.map((x, i) => {
                    return (
                        <MenuItem key={i} value={x.id} sx={{ padding: "7px" }}>
                            {x.title}
                        </MenuItem>
                    );
                })}
            </Select>
            -
            <Select
                value={selectedYear}
                MenuProps={customMenuProps}
                sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    ".MuiSelect-select": {
                        padding: "4px 12px",
                    },
                }}
                onChange={(e) => {
                    const {
                        target: { value },
                    } = e;

                    setSelectedYear(value);
                }}
            >
                <MenuItem
                    value={0}
                    sx={{ padding: "7px", display: "none", color: "grey" }}
                >
                    Year
                </MenuItem>
                {yearRange.map((x, i) => {
                    return (
                        <MenuItem key={i} value={x} sx={{ padding: "7px" }}>
                            {x}
                        </MenuItem>
                    );
                })}
            </Select>

            <span
                onClick={() => {
                    setSelectedMonth(0);
                    setSelectedYear(0);
                }}
                style={{
                    display: selectedMonth !== 0 || selectedYear !== 0 ? "block" : "none",
                    cursor: "pointer",
                    background: "#FACCCC",
                    padding: "3px 7px",
                    borderRadius: "7px",
                    color: "red",
                    marginLeft: '0.5rem'
                }}
            >
                Clear
            </span>
        </Box>
    );
};

export default SlotDrop;
