import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const activeSteps = [
  "New order",
  "Confirmed",
  "On the way",
  "Arrived",
  "Start session",
  "Completed",
];

const cancelledSteps1 = [
  "New order",
  "Cancelled",

];
const cancelledSteps2 = [
  "New order",
  "Confirmed",
  "Cancelled",

];

export default function StepperComponent({ data }) {
  const [activeStep, setActiveStep] = React.useState();

  React.useEffect(() => {
    setActiveStep(data && data[data?.length - 1]?.session_status);
  }, [data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ padding: "15px" }}
      >
        {(activeStep === 7
          ? data?.length === 2
            ? cancelledSteps1
            : cancelledSteps2
          : activeSteps
        ).map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-labelContainer": {
                    color: "#aaaaaa",
                  },
                  "& .MuiSvgIcon-root": {
                    color:
                      activeStep === 7 && index === data?.length - 1
                        ? "#f44c4c"
                        : activeStep === 7 && index > 2
                          ? "grey"
                          : "null",
                  },
                }}
              >
                <span
                  style={{
                    color:
                      activeStep === 7 &&
                      index === data?.length - 1 &&
                      "#f44c4c",
                  }}
                >
                  {label}
                </span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
