import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Logo from "../../../Assets/image/dash/logo.svg";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import LogoutIcon from "../../../Assets/image/dash/sb/Login.svg";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../../Common/Routes/Path";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  bgcolor: "background.paper",
  borderRadius: "12px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function LogoutModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    handleClose();
    toast.success("Logout successfully.");
    navigate(PathList.Login);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50px",
          boxSizing: "border-box",
          borderRadius: "5px",

          m: "0.5rem",
          "&:hover": {
            background: theme.palette.primary.BlendO,
          },
        }}
        onClick={handleOpen}
      >
        <List
          style={{
            width: "100%",
          }}
        >
          <ListItem key={"logout"} disablePadding sx={{ width: "100%" }}>
            <ListItemButton sx={{ width: "100%", pr: "50px", }}>
              <ListItemIcon
                sx={{
                  minWidth: "44px",
                }}
              >
                <img
                  src={LogoutIcon}
                  style={{
                    boxSizing: "border-box",
                    height: "30px",
                    width: "30px",
                    border: "none",
                  }}
                  alt="Logo"
                  srcSet=""
                />
              </ListItemIcon> 
              <ListItemText
                primary={"Log out"}
                sx={{
                  color: theme.palette.text.main,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
              "&:hover": {
                background: theme.palette.primary.BlendO,
              },
            }}
          >
            <img src={Logo} alt="Logo" srcSet="" />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: theme.palette.primary.paper,
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Logout
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, textAlign: "center" }}
          >
            Are you sure ?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              mt: 1,
              fontSize: "18px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleClose}
            >
              Cancle
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                background: theme.palette.primary.Blend,
                color: "#eee",
              }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
