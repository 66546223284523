import React from "react";
import { Button, Menu, MenuItem, Divider } from "@mui/material";
import dotLogo from "../../Assets/image/dash/dots.svg";
import deleteicon from "../../Assets/image/FAQ/deleteicon.png";
import editIcon from "../../Assets/image/FAQ/Pen.png";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { toast } from "react-toastify";
import { apiList } from "../../Common/Api/ApiList";

export default function FAQActionButton({
  id,
  handleEdit,
  fetchUserData,
  data,
  modalMode,
  selectedFAQ,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isActionInProgress, setIsActionInProgress] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (isActionInProgress) return;

    setIsActionInProgress(true);

    try {
      const response = await AxiosInstance.post(apiList.AddFAQs, {
        id,
        type: 3,
      });
      if (response?.data?.s === 1) {
        toast.success("FAQ Deleted Successfully");
        await fetchUserData();
        handleClose();
      } else {
        toast.error("Failed to delete FAQ");
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      console.log(error?.response?.data?.message || "Failed to delete FAQ");
    } finally {
      setIsActionInProgress(false);
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={dotLogo} alt="" srcSet="" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDelete} disabled={isActionInProgress}>
          <img src={deleteicon} style={{ marginRight: "0.5rem" }} alt="" />{" "}
          Delete
        </MenuItem>
        <Divider sx={{ m: "0 7px" }} />
        <MenuItem
          onClick={() => {
            if (isActionInProgress) return; // Prevent multiple clicks
            handleClose();
            handleEdit(data, selectedFAQ);
          }}
          sx={{ width: "160px" }}
          disabled={isActionInProgress}
        >
          <img src={editIcon} style={{ marginRight: "0.5rem" }} alt="" /> Edit
        </MenuItem>
      </Menu>
    </div>
  );
}
