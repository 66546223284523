import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoDataImg from "../../../Common/NoData";
import Loader from "../../../Common/Loader";

export default function Experince({ data }) {
  const [addresses, setAddresses] = useState([]);
  const [loading, setloading] = useState(true);
  const d = data?.options?.basic_details;

  const GetAddressFromLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxwKI7SmYgpPKeO2-vkOda_SDEdqgzYyE`
      );
      const data = await response?.json();
      if (data?.status === "OK") {
        return data?.results[0]?.formatted_address;
      } else {
        throw new Error("Failed to fetch address");
      }
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      setloading(true);
      if (d?.locations) {
        const locations = d?.locations;
        const newAddresses = await Promise.all(
          locations.map(async (location) => {
            return await GetAddressFromLng(location?.lat, location?.lang);
          })
        );

        // Filter out any null addresses
        const validAddresses = newAddresses.filter(
          (address) => address !== null
        );

        // Update the state with the valid addresses
        setAddresses(validAddresses);
        setloading(false);
      }
    };

    fetchAddresses();
  }, [data]);
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        // minHeight: '350px',
        overflow: { md: "scroll" },
        borderRadius: "8px",
      }}
      className="HideScrollBar"
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          color: "white",
          height: "50px",
          position: "sticky",
          top: "0",
          zIndex: 1,
        }}
      >
        Experiences
      </Typography>
      <Box
        className="HideScrollBar"
        sx={{ height: "calc(100% - 50px)", overflow: "scroll" }}
      >
        {d?.experiences?.length > 0 ? (
          d?.experiences?.map((experience, index) => {
            return (
              <>
                <Box key={index} sx={{ px: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      pt: 1,
                    }}
                  >
                    {experience?.gym_name}
                  </Typography>
                  <Typography
                    sx={{
                      pb: 1,
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {experience?.years} Year
                  </Typography>
                  {d?.experiences?.length - 1 !== index ? (
                    <hr style={{ background: "#CECECE", opacity: "0.4" }} />
                  ) : null}
                </Box>
              </>
            );
          })
        ) : (
          <Box sx={{ padding: "1rem" }}>
            <NoDataImg />
          </Box>
        )}
        <Typography sx={{ p: 1, background: "#e2e2e2", fontWeight: "bold" }}>
          Location Preferences
        </Typography>

        <Box sx={{ display: "flex", p: 2, gap: "5px" }}>
          {d?.location_preferences?.my_place === 1 ||
          d?.location_preferences?.user_place === 1 ? (
            <>
              <Typography
                sx={{
                  fontSize: "16px",
                  background: "#CEE5FF",
                  p: 1,
                  borderRadius: "5px",
                  display:
                    d?.location_preferences?.user_place === 1
                      ? "block"
                      : "none",
                }}
              >
                {d?.location_preferences?.user_place === 1 && "User Place"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  background: "#CEE5FF",
                  p: 1,
                  borderRadius: "5px",
                  display:
                    d?.location_preferences?.my_place === 1 ? "block" : "none",
                }}
              >
                {d?.location_preferences?.my_place === 1 && "My Place"}
              </Typography>
            </>
          ) : (
            <Box sx={{ padding: "1rem", width: "100%" }}>
              <NoDataImg />
            </Box>
          )}
        </Box>
        <Typography sx={{ p: 1, background: "#e2e2e2", fontWeight: "bold" }}>
          Location
        </Typography>
        <Box
          sx={{
            p: 2,
            gap: "5px",
            display: "flex",
          }}
        >
          {loading ? (
            <Loader />
          ) : addresses && addresses?.length > 0 ? (
            addresses.map((trainerlocation, i) => {
              return (
                <>
                  <Typography
                    key={i}
                    sx={{
                      fontSize: "16px",
                      background: "#CEE5FF",
                      p: 1,
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/search/" + trainerlocation
                      );
                    }}
                  >
                    {trainerlocation}
                  </Typography>
                </>
              );
            })
          ) : (
            <Box sx={{ padding: "1rem", width: "100%" }}>
              <NoDataImg />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
